export const styles = {
  googleButton: {
    padding: '2px 6px',
    width: '311px',
    marginBottom: '24px',
    borderRadius: '4px',
    backgroundColor: '#4285F4',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24)',
    '&:hover': {
      backgroundColor: '#42A0F4'
    },
    '& span': {
      justifyContent: 'start'
    },
    '& .MuiAvatar-square': {
      height: '45.09px',
      width: '45.09px',
      borderRadius: '3px',
      backgroundColor: '#FFF'
    },
    '& .GoogleButton-label': {
      height: '17px',
      width: '264px',
      color: '#FFF',
      fontFamily: 'Product Sans',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.25px',
      lineHeight: '19px',
      textAlign: 'center'
    },
    '& img': {
      height: '32px',
      width: '32px'
    }
  }
}
