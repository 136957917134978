import { useCallback, useEffect, useState, type DependencyList } from 'react'

export const useService = <T = Record<string, unknown>>(
  {
    service,
    staleData = false
  }: {
    service: () => unknown
    staleData?: boolean
  },
  dependencies: DependencyList
) => {
  const [error, setError] = useState<Error>()
  const [fetching, setFetching] = useState(false)
  const [data, setData] = useState<T>()

  const getData = useCallback(async () => {
    if (!staleData) {
      setData(undefined)
    }

    try {
      setFetching(true)
      const serviceData = await service()
      setData(serviceData as T)
    } catch (err) {
      setError(err as Error)
    } finally {
      setFetching(false)
    }
  }, dependencies)

  useEffect(() => {
    void getData()
  }, dependencies)

  if (error) {
    setError(undefined)
    throw error
  }

  return { data, fetching }
}
