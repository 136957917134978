import { Strings } from '@boommed-suite/typescript-crossplatform'
import i18next from 'i18next'
import { useCallback } from 'react'

export const useDateFormatter = () => {
  const formatDate = useCallback((date?: number | string) => {
    if (!date) return Strings.empty()

    const formattedDate = new Intl.DateTimeFormat(i18next.language, {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }).format(new Date(date))

    return formattedDate
  }, [])

  return { formatDate }
}
