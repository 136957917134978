import { Button, type ButtonProps } from '@mui/material'
import React from 'react'
import { styles } from './SuiteButton.styles'

export const SuiteButton = (props: ButtonProps) => {
  const style = props.disabled ? styles.buttonDisabled : {}

  return (
    <Button
      {...props}
      style={{
        ...styles.default,
        ...style,
      }}
    />
  )
}
