import { type UserApi } from '@boommed-suite/contracts'
import AddIcon from '@mui/icons-material/Add'
import { Grid } from '@mui/material'
import { useInjection } from 'inversify-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import { AppRoutes } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { useService } from '../../hooks/useService'
import { MasterPage } from '../MasterPage/MasterPage'
import { styles } from './TenantsPage.styles'
import { TenantCard } from './components/TenantCard'

const useDeepLink = () => {
  const boommedService = useInjection(BoommedService)
  const { menu, setMenu } = useAppContext()

  useService<UserApi.ListTenantsResponse>(
    {
      service: async () => {
        if (menu && !menu.items?.tenants && menu._links?.parentSignin) {
          await boommedService.fetch(menu._links.parentSignin)

          const [parentTenantMenu, error] = await boommedService.loadMenu()

          if (error) {
            throw error
          }

          setMenu(parentTenantMenu)
        }
      }
    },
    [menu]
  )
}

export const TenantsPage = () => {
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()
  const { t } = useTranslation()

  useDeepLink()

  const { data } = useService<UserApi.ListTenantsResponse>(
    {
      service: async () => {
        if (menu?.items?.tenants?._links?.list) {
          const [result, error] = await boommedService.fetch<UserApi.ListTenantsResponse>(
            menu.items.tenants._links.list
          )

          if (error) {
            throw error
          }

          return result
        }

        return undefined
      }
    },
    [menu]
  )

  return (
    <MasterPage
      title={t('tenants')}
      actions={
        menu?.items?.tenants?._links?.new
          ? [
              {
                title: t('tenant_submit'),
                icon: <AddIcon />,
                href: AppRoutes.tenantAdd
              }
            ]
          : []
      }
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={styles.container}
        justifyContent="center"
        alignContent="center"
      >
        {data?.tenants?.map((tenant) => (
          <Grid
            key={tenant.id}
            item
            xs={2}
            sm={4}
            md={4}
            justifyContent="center"
            alignContent="center"
          >
            <TenantCard tenant={tenant} />
          </Grid>
        ))}
      </Grid>
    </MasterPage>
  )
}
