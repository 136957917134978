import React, { useEffect } from 'react'
import { AppRoutes, useAppNavigate } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { useTenantDeepLink } from '../../hooks/useTenantDeepLink'
import { MasterPage } from '../MasterPage/MasterPage'

export const TenantPage = () => {
  const navigate = useAppNavigate()
  const { tenant } = useAppContext()

  useTenantDeepLink()

  useEffect(() => {
    if (tenant?.id) {
      if (tenant.externalLink) {
        window.location.href = tenant.externalLink
      } else {
        navigate(AppRoutes.users, { tenantId: tenant.id })
      }
    }
  }, [tenant])

  return <MasterPage title={tenant?.name} menuOpen={true}></MasterPage>
}
