import { mdiClose } from '@mdi/js'
import MDIIcon from '@mdi/react'
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppNavigate } from '../../../app/AppRouter'
import { theme } from '../../../app/Theme'
import { SuiteButton } from '../../../components/SuiteButton/SuiteButton'
import { TemplatedForm } from '../../../components/TemplatedForm/TemplatedForm'
import {
  TemplatedFormContextProvider,
  useTemplatedFormContext,
} from '../../../components/TemplatedForm/TemplatedFormContext'
import { styles } from './NewAppointment.styles'

const NewAppointmentHeader = () => {
  const { t } = useTranslation()
  const navigate = useAppNavigate()

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack direction="row" sx={styles.header}>
      <Typography variant="h4" flexGrow={1} fontWeight="bold">
        {t('appointment_new_title')}
      </Typography>
      <Box sx={styles.closeButton} onClick={onClose}>
        <MDIIcon path={mdiClose} size={0.75} />
      </Box>
    </Stack>
  )
}

const NewAppointmentForm = () => {
  const { onFormChanged } = useTemplatedFormContext()

  return (
    <Stack direction="column" sx={styles.form}>
      <TemplatedForm
        fields={{
          patient: {
            order: 0,
            type: 'patient',
            label: 'patient',
            required: true,
          },
          symptoms: {
            order: 1,
            type: 'symptoms',
            label: 'symptoms',
            required: true,
          },
          diagnosticArea: {
            order: 2,
            type: 'diagnostic_area',
            label: 'diagnostic_area',
            required: true,
          },
          medicalAct: {
            order: 3,
            type: 'medical_act',
            label: 'medical_act',
            required: true,
          },
          date: {
            order: 4,
            type: 'datetime',
            label: 'appointments_date',
            required: true,
          },
          weight: {
            order: 4,
            type: 'weight',
            label: 'weight',
            required: false,
          },
          comments: {
            order: 4,
            type: 'text_area',
            label: 'appointments_comment',
            required: false,
          },
        }}
        onChange={() => {
          onFormChanged()
        }}
        onSubmit={(values) => {
          onFormChanged(values)
        }}
        displayCancelButton={false}
        displaySaveButton={false}
      />
    </Stack>
  )
}

const NewAppointmentFooter = () => {
  const { t } = useTranslation()

  const { data } = useTemplatedFormContext()

  return (
    <Stack
      sx={styles.footerContainer}
      direction="row"
      spacing={theme.spacing(2)}
    >
      <Box sx={styles.footerDiscardContainer}>
        <Button variant="text">{t('discard')}</Button>
      </Box>
      <FormGroup>
        <FormControlLabel
          sx={styles.footerHospitalizationLabel}
          control={<Switch defaultChecked={false} />}
          label={t('hospitalization')}
        />
      </FormGroup>
      <SuiteButton variant="contained" disabled={data === undefined}>
        {t('appointment_new_create')}
      </SuiteButton>
    </Stack>
  )
}

export const NewAppointment = () => {
  const { action: routeAction } = useParams()

  return (
    <TemplatedFormContextProvider>
      <Drawer
        anchor="right"
        open={routeAction === 'add'}
        sx={styles.drawer}
        variant="persistent"
      >
        <Box sx={styles.container}>
          <NewAppointmentHeader />
          <NewAppointmentForm />
          <NewAppointmentFooter />
        </Box>
      </Drawer>
    </TemplatedFormContextProvider>
  )
}
