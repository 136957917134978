import { Guid } from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import {
  AsyncAutoComplete,
  type AsyncAutoCompleteProps,
} from '../AsyncAutoComplete/AsyncAutoComplete'

interface MockSymptomResponse {
  id: Guid
  name: string
}

type SymptomsSearchFieldProps = Pick<
AsyncAutoCompleteProps<MockSymptomResponse>,
'onItemSelected' | 'required'
> & { field?: string }

export const SymptomsSearchField = ({
  onItemSelected,
  required,
  field = 'symptoms',
}: SymptomsSearchFieldProps) => {
  const { t } = useTranslation()
  const boommedService = useInjection(BoommedService)

  const fetchData = useCallback(async (): Promise<MockSymptomResponse[]> => {
    return [
      {
        id: Guid.New(),
        name: 'fever',
      },
      {
        id: Guid.New(),
        name: 'cough',
      },
      {
        id: Guid.New(),
        name: 'headache',
      },
    ]
  }, [boommedService])

  return (
    <AsyncAutoComplete
      fetchData={fetchData}
      resolveLabel={(item) => item.name}
      isEqualValue={(item, value) => item.id === value?.id}
      onItemSelected={onItemSelected}
      label={t('symptoms')}
      required={required}
      multiple={true}
      name={field}
      renderOption={(optionProps, option) => {
        return (
          <li key={option.id.toString()} {...optionProps}>
            {option.name}
          </li>
        )
      }}
    />
  )
}
