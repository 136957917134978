import EmailIcon from '@mui/icons-material/Email'
import { Button, Link, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../../app/contexts/AppContext'
import { styles } from './Help.styles'

export const Help = () => {
  const { t } = useTranslation()
  const { menu } = useAppContext()

  return (
    <Button
      color="secondary"
      sx={styles.help}
      variant="contained"
      startIcon={<EmailIcon style={{ color: '#FF0049' }} />}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        <Link underline="none" color="inherit" href={`mailto:${menu?._links?.email.href}`}>
          {t('email_message').toLocaleUpperCase()}
        </Link>
      </Typography>
    </Button>
  )
}
