import { type UserApi } from '@boommed-suite/contracts'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Stack, Typography } from '@mui/material'
import { DataGrid, type GridRowSelectionModel } from '@mui/x-data-grid'
import { useInjection } from 'inversify-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import { AppRoutes, buildNavigationPath } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { useService } from '../../hooks/useService'
import { useTenantDeepLink } from '../../hooks/useTenantDeepLink'
import { MasterPage, type MasterPageAction } from '../MasterPage/MasterPage'
import { styles } from './UsersPage.styles'
import { EditUserDialog } from './components/EditUserDialog'

export const UsersPage = () => {
  const boommedService = useInjection(BoommedService)
  const { menu, tenant } = useAppContext()
  const { t } = useTranslation()
  const { formatDate } = useDateFormatter()
  const [selectedUsers, setSelectedUsers] = useState<UserApi.UserDetailResponse[]>([])
  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }
  const [refreshUsers, setRefreshUsers] = useState<boolean>(true)
  const [editUser, setEditUser] = useState<UserApi.UserDetailResponse>()
  const [users, setUsers] = useState<UserApi.UsersListResponse>()

  useTenantDeepLink()

  const { fetching } = useService<UserApi.UsersListResponse>(
    {
      service: async () => {
        if (menu?.items?.users?._links?.list && refreshUsers) {
          setSelectedUsers([])
          setRefreshUsers(false)

          const [pagedUsers, error] =
            await boommedService.fetch<UserApi.UsersListResponse>(
              menu.items.users._links.list,
            )

          if (error) {
            throw error
          }

          setUsers(pagedUsers)
        }
      },
    },
    [menu, refreshUsers],
  )

  const deleteSelectedUsers = useCallback(async () => {
    if (users) {
      const [_, error] = await boommedService.fetch(users._links.delete, {
        ids: selectedUsers.map((user) => user.id),
      })

      if (error) {
        setUnexpectedError(error)
        return
      }

      setRefreshUsers(true)
    }
  }, [selectedUsers])

  const masterActions = useMemo(() => {
    const actions: MasterPageAction[] = []

    if (menu?.items?.users?._links?.new && tenant?.id) {
      actions.push({
        title: t('add'),
        icon: <AddIcon />,
        href: buildNavigationPath(AppRoutes.userAdd, { tenantId: tenant?.id }),
      })
    }

    const displayDeleteButton =
      selectedUsers.length &&
      selectedUsers.reduce(
        (display, user) => display && Boolean(user._links.delete),
        true,
      )

    if (displayDeleteButton) {
      actions.push({
        title: t('delete'),
        icon: <DeleteIcon />,
        sx: styles.deleteButton,
        onClick: deleteSelectedUsers,
      })
    }

    return actions
  }, [menu, selectedUsers, tenant])

  const onSelectionChange = useCallback(
    (selectedItems: GridRowSelectionModel) => {
      const filteredUsers = users?.page.elements.filter((user) =>
        selectedItems.includes(user.id),
      )
      setSelectedUsers(filteredUsers ?? [])
    },
    [users],
  )

  const onEditUser = useCallback((user: UserApi.UserDetailResponse) => {
    setEditUser(user)
  }, [])

  const onFinishEditUser = useCallback((submitted: boolean) => {
    setEditUser(undefined)
    if (submitted) {
      setRefreshUsers(true)
    }
  }, [])

  return (
    <MasterPage title={t('users')} menuOpen={true} actions={masterActions}>
      <DataGrid
        loading={fetching}
        sx={styles.root}
        columnBufferPx={3}
        rows={users?.page.elements ?? []}
        onRowSelectionModelChange={onSelectionChange}
        columns={[
          {
            field: 'email',
            headerName: t('users_email_header'),
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            renderCell: (params) => (
              <Stack direction="column" sx={styles.userNameCellContainer}>
                {params.row.name && (
                  <Typography noWrap variant="h5">
                    {params.row.name}
                  </Typography>
                )}
                {params.row.email && (
                  <Typography noWrap variant={params.row.name ? 'h6' : 'h5'}>
                    {params.row.email}
                  </Typography>
                )}
              </Stack>
            ),
          },
          {
            field: 'roles',
            headerName: t('users_roles_header'),
            sortable: false,
            flex: 1,
            renderCell: (params) => (
              <Button
                variant="text"
                sx={styles.rolesButton}
                onClick={() => {
                  onEditUser(params.row)
                }}
              >
                <Typography noWrap variant="h5">
                  {params.row.roles.map((role) => t(role)).join(', ')}
                </Typography>
              </Button>
            ),
          },
          {
            field: 'createdAt',
            headerName: t('users_created_at_header'),
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            valueGetter: (params) => formatDate(params),
          },
        ]}
        checkboxSelection
        pageSizeOptions={[users?.page.pageSize ?? 0]}
        autoHeight
        hideFooterPagination
        slots={{
          footer: () => (
            <Typography variant="h6" sx={styles.footer}>
              {t('total_records', { total: users?.page.elements.length ?? 0 })}
            </Typography>
          ),
        }}
      />
      {editUser && users && (
        <EditUserDialog
          user={editUser}
          editTemplate={users.templates.edit}
          onClose={onFinishEditUser}
        />
      )}
    </MasterPage>
  )
}
