import { decorate, injectable } from 'inversify'

export class Crashlytics {
  public error (error: unknown, metadata?: unknown): void {
    // eslint-disable-next-line no-console
    console.error(
      'Crashlytics - Unexpected error',
      { error, metadata },
      error as Error,
      Crashlytics.name
    )
  }
}

decorate(injectable(), Crashlytics)
