import {
  HttpRequestError,
  StatusCode,
  isError
} from '@boommed-suite/typescript-crossplatform'
import { Avatar, Button } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'
import { useInjection } from 'inversify-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../../domain/services/BoommedService'
import { Crashlytics } from '../../../../domain/services/Crashlytics'
import { useAppContext } from '../../../app/contexts/AppContext'
import { useNotificationContext } from '../../../app/contexts/NotificationContext'
import { useService } from '../../../hooks/useService'
import { styles } from './GoogleButton.styles'

export const GoogleButton = () => {
  const { t } = useTranslation()
  const { menu, setMenu } = useAppContext()
  const { openNotification } = useNotificationContext()
  const boommedService = useInjection(BoommedService)
  const crashlytics = useInjection(Crashlytics)

  const [accessToken, setAccessToken] = useState<string>()

  const login = useGoogleLogin({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    onSuccess: ({ access_token }) => { setAccessToken(access_token) },
    onError: (error) => {
      crashlytics.error(error)
    }
  })

  useService(
    {
      service: async () => {
        const signinLink = menu?.items?.authentication?.items?.google?._links?.signin
        if (accessToken && signinLink) {
          const [_, signinError] = await boommedService.fetch(signinLink, { accessToken })

          if (isError(HttpRequestError)(signinError)) {
            if (
              [StatusCode.UNAUTHORIZED, StatusCode.FORBIDDEN].includes(
                signinError.statusCode
              )
            ) {
              openNotification({
                severity: 'error',
                text: t('non_authorized_error')
              })
              return
            }
            throw signinError
          }

          if (isError(Error)(signinError)) {
            throw signinError
          }

          const [loggedInMenu, loggedInMenuError] = await boommedService.loadMenu()

          if (loggedInMenuError) {
            throw loggedInMenuError
          }

          setAccessToken(undefined)

          if (loggedInMenu) {
            setMenu(loggedInMenu)
          }
        }
      }
    },
    [accessToken]
  )

  return (
    <Button
      sx={styles.googleButton}
      size="large"
      onClick={() => { login() }}
      startIcon={<Avatar variant="square" src={menu?._links?.googleLogo.href} />}
    >
      <span className={'GoogleButton-label'}>
        {t('login_google').toLocaleUpperCase()}
      </span>
    </Button>
  )
}
