import { Box, Stack } from '@mui/material'
import React from 'react'
import { useAppContext } from '../../app/contexts/AppContext'
import { styles } from './LoginPage.styles'
import { GoogleButton } from './components/GoogleButton'
import { Help } from './components/Help'

export const LoginPage = () => {
  const { menu } = useAppContext()

  return (
    <Box
      component="div"
      role="background"
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="flex-end"
      sx={styles.root}
      style={
        menu?._links?.background?.href
          ? {
              backgroundImage: `url(${menu._links.background.href})`
            }
          : {}
      }
    >
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        {menu?._links?.boommedLogo?.href && (
          <img
            style={styles.bigLogo}
            alt="boommed-logo"
            src={menu._links.boommedLogo.href}
          />
        )}
        {menu?.items?.authentication?.items?.google?._links?.signin && <GoogleButton />}
        {menu?._links?.email?.href && <Help />}
        {menu?._links?.logoWhiteLetter?.href && (
          <img
            style={styles.smallLogo}
            alt="boommed-small-logo"
            src={menu._links.logoWhiteLetter.href}
          />
        )}
      </Stack>
    </Box>
  )
}
