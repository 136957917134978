import { type SuiteApi } from '@boommed-suite/contracts'
import { Strings } from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import { useAppContext } from '../../app/contexts/AppContext'
import {
  AsyncAutoComplete,
  type AsyncAutoCompleteProps,
} from '../AsyncAutoComplete/AsyncAutoComplete'

type ClientSearchFieldProps = Pick<
AsyncAutoCompleteProps<SuiteApi.ClientDetailResponse>,
'onItemSelected' | 'required'
> & { field?: string }

export const ClientSearchField = ({
  onItemSelected,
  required,
  field = 'client',
}: ClientSearchFieldProps) => {
  const { t } = useTranslation()
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()

  const fetchData = useCallback(
    async (
      searchTerm: string | undefined,
    ): Promise<SuiteApi.ClientDetailResponse[]> => {
      const listLink = menu?.items?.clients._links?.list

      if (listLink !== undefined) {
        const [pagedClients] =
          await boommedService.fetch<SuiteApi.ClientsListResponse>(
            listLink,
            undefined,
            { searchTerm: searchTerm ?? Strings.empty() },
          )

        return pagedClients?.page.elements ?? []
      }

      return []
    },
    [menu, boommedService],
  )

  return (
    <AsyncAutoComplete
      fetchData={fetchData}
      resolveLabel={(item) => item.name}
      isEqualValue={(item, value) => item.id === value?.id}
      onItemSelected={onItemSelected}
      label={t('client')}
      required={required}
      name={field}
      renderOption={(optionProps, option) => {
        return (
          <li key={option.id} {...optionProps}>
            {option.name}
            <br />
            {option.phoneNumber}
          </li>
        )
      }}
    />
  )
}
