import { type UserApi } from '@boommed-suite/contracts'
import { Strings, parseTemplate } from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import { useParams } from 'react-router-dom'
import { BoommedService } from '../../domain/services/BoommedService'
import { useAppContext } from '../app/contexts/AppContext'
import { useService } from './useService'

export const useTenantDeepLink = () => {
  const boommedService = useInjection(BoommedService)
  const { tenantId } = useParams()
  const { menu, setMenu } = useAppContext()

  useService<UserApi.TenantDetailResponse>(
    {
      service: async () => {
        if (!menu?.items?.tenant && tenantId && Strings.notEmpty(tenantId)) {
          const signinLink = menu?.items?.tenants?._links?.signin

          if (signinLink) {
            const [_, signinError] = await boommedService.fetch({
              ...signinLink,
              href: parseTemplate(signinLink.href).expand({ tenantId })
            })

            if (signinError) {
              throw signinError
            }

            const [tenantMenu, error] = await boommedService.loadMenu()

            if (error) {
              throw error
            }

            setMenu(tenantMenu)
          }
        }

        return undefined
      }
    },
    [menu]
  )
}
