import React from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { AddClientPage } from '../pages/AddClientPage'
import { AddUserPage } from '../pages/AddUserPage'
import { AppointmentsPage } from '../pages/AppointmentsPage/AppointmentsPage'
import { ClientsPage } from '../pages/ClientsPage/ClientsPage'
import { CreateTenantPage } from '../pages/CreateTenantPage'
import { TenantPage } from '../pages/TenantPage/TenantPage'
import { TenantsPage } from '../pages/TenantsPage/TenantsPage'
import { UnexpectedErrorPage } from '../pages/UnexpectedErrorPage'
import { UserProfilePage } from '../pages/UserProfilePage'
import { UsersPage } from '../pages/UsersPage/UsersPage'

export const AppRoutes = {
  root: '/',
  tenant: '/tenant/:tenantId',
  tenantAdd: '/tenants/add',
  tenants: '/tenants',
  users: '/tenant/:tenantId/users',
  userAdd: '/tenant/:tenantId/user/add',
  userProfile: '/me',
  clients: '/tenant/:tenantId/clients',
  clientAdd: '/tenant/:tenantId/clients/add',
  appointments: '/tenant/:tenantId/appointments',
  appointmentAdd: '/tenant/:tenantId/appointment/:action',
}

export const AppRouter = () => {
  const { t } = useTranslation()

  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoutes.root} element={<Navigate to="/tenants" />} />
        <Route path={AppRoutes.tenants} element={<TenantsPage />} />
        <Route path={AppRoutes.tenant} element={<TenantPage />} />
        <Route path={AppRoutes.tenantAdd} element={<CreateTenantPage />} />
        <Route path={AppRoutes.users} element={<UsersPage />} />
        <Route path={AppRoutes.userAdd} element={<AddUserPage />} />
        <Route path={AppRoutes.userProfile} element={<UserProfilePage />} />
        <Route path={AppRoutes.clients} element={<ClientsPage />} />
        <Route path={AppRoutes.clientAdd} element={<AddClientPage />} />
        <Route path={AppRoutes.appointments} element={<AppointmentsPage />} />
        <Route path={AppRoutes.appointmentAdd} element={<AppointmentsPage />} />
        <Route
          path="*"
          element={<UnexpectedErrorPage>{t('page_not_found')}</UnexpectedErrorPage>}
        />
      </Routes>
    </BrowserRouter>
  )
}

export const buildNavigationPath = (
  route: string,
  replacements: Record<string, string>,
) =>
  Object.entries(replacements).reduce(
    (finalRoute, [key, value]) => finalRoute.replace(`:${key}`, value),
    route,
  )

export const useAppNavigate = () => {
  const navigate = useNavigate()

  return (route: string | number, replacements: Record<string, string> = {}) => {
    if (typeof route === 'number') {
      navigate(route)
    } else {
      const path = buildNavigationPath(route, replacements)
      navigate(path)
    }
  }
}
