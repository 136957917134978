import { type UserApi } from '@boommed-suite/contracts'
import { useInjection } from 'inversify-react'
import React, {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction
} from 'react'
import { BoommedService } from '../../../domain/services/BoommedService'
import { useService } from '../../hooks/useService'

export interface AppContextStore {
  tenant?: UserApi.TenantDetailResponse
  menu?: UserApi.MenuItem
  setMenu: Dispatch<SetStateAction<UserApi.MenuItem | undefined>>
  user?: UserApi.UserInfoResponse
  setUser: Dispatch<SetStateAction<UserApi.UserInfoResponse | undefined>>
}

export const AppContext = createContext<AppContextStore>({} as AppContextStore)

interface AppContextProviderProps {
  children: ReactNode
  menu?: UserApi.MenuItem
  tenant?: UserApi.TenantDetailResponse
}

export const AppContextProvider = ({
  children,
  menu: injectedMenu,
  tenant: injectedTenant
}: AppContextProviderProps) => {
  const [menu, setMenu] = useState<UserApi.MenuItem | undefined>(injectedMenu)
  const [user, setUser] = useState<UserApi.UserInfoResponse>()
  const [tenant, setTenant] = useState<UserApi.TenantDetailResponse | undefined>(injectedTenant)

  const boommedService = useInjection(BoommedService)

  useService(
    {
      service: async () => {
        if (!menu) {
          const [loadedMenu] = await boommedService.loadMenu()
          if (loadedMenu) {
            setMenu(loadedMenu)
          }
        }
      }
    },
    [menu]
  )

  useService(
    {
      service: async () => {
        if (menu?.signedIn && menu?.items?.user?._links?.info) {
          const [result] = await boommedService.fetch<UserApi.UserInfoResponse>(
            menu.items.user._links.info
          )
          setUser(result)
        }
      }
    },
    [menu]
  )

  useService<UserApi.TenantDetailResponse>(
    {
      service: async () => {
        if (menu?.items?.tenant?._links?.self) {
          const [currentTenant] =
            await boommedService.fetch<UserApi.TenantDetailResponse>(
              menu.items.tenant._links.self
            )

          if (currentTenant) {
            setTenant(currentTenant)
          }
        }
      }
    },
    [menu]
  )

  return (
    <AppContext.Provider
      value={{
        tenant,
        menu,
        setMenu,
        user,
        setUser
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
