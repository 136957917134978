import { Logout } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import { useInjection } from 'inversify-react'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../../domain/services/BoommedService'
import { useAppNavigate } from '../../../app/AppRouter'
import { useAppContext } from '../../../app/contexts/AppContext'
import { styles } from './UserProfile.styles'

export const UserProfile = () => {
  const { t } = useTranslation()
  const { user, menu, setMenu } = useAppContext()
  const navigate = useAppNavigate()
  const boommedService = useInjection(BoommedService)

  const avatar = useRef(null)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = () => {
    setAnchorEl(avatar.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogout = useCallback(async () => {
    if (menu?.items?.user?._links?.signout) {
      await boommedService.fetch(menu.items.user._links.signout)
      handleClose()
      setMenu(undefined)
      navigate('/')
    }
  }, [user, menu])

  const onEditProfile = useCallback(async () => {
    handleClose()
    navigate('/me')
  }, [])

  return (
    user && (
      <>
        <IconButton color="inherit" onClick={handleClick}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar ref={avatar} src={user.avatar} />
            <Typography noWrap variant="h6">
              {user.name || user.email}
            </Typography>
          </Stack>
        </IconButton>
        <Menu
          color="inherit"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: styles.menu
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={onEditProfile}>
            <ListItemIcon>
              <EditIcon fontSize="small" sx={styles.icon} />
            </ListItemIcon>
            <Typography variant="h6">{t('edit_profile')}</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <Logout fontSize="small" sx={styles.icon} />
            </ListItemIcon>
            <Typography variant="h6">{t('logout')}</Typography>
          </MenuItem>
        </Menu>
      </>
    )
  )
}
