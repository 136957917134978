import { CustomPalette } from '../../app/Theme'

export const styles: Record<string, React.CSSProperties | undefined> = {
  default: {
    textTransform: 'capitalize',
  },
  buttonDisabled: {
    backgroundColor: CustomPalette.master.primary.main,
    opacity: 0.5,
    color: CustomPalette.master.white,
  },
}
