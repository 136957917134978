import { type UserApi } from '@boommed-suite/contracts'
import {
  Err,
  HttpMethods,
  HttpRequestError,
  Link,
  Ok,
  Strings,
  parseTemplate,
  type HttpRequest,
  type HttpResponse,
  type NetworkError,
  type Result
} from '@boommed-suite/typescript-crossplatform'
import { decorate, injectable } from 'inversify'
import { configuration } from '../../ui/app/configuration/Configuration'

export class BoommedService {
  constructor (private readonly http: HttpRequest) {}

  public async loadMenu (): Promise<Result<UserApi.MenuItem>> {
    const menuLink = new Link({
      method: HttpMethods.GET,
      href: configuration.boommed.baseUrl ?? Strings.empty()
    })

    return await this.fetch(menuLink)
  }

  private async internalFetch<T>(
    link: Link,
    data?: unknown,
    uriParameters?: Record<string, string>
  ): Promise<Result<HttpResponse<T>, NetworkError | HttpRequestError<T>>> {
    const hrefTemplate = parseTemplate(link.href)
    const href = hrefTemplate.expand(uriParameters ?? {})

    return await this.http.fetch<T>({
      link: {
        ...link,
        href,
        headers: { ...(link.headers ?? {}), ...configuration.boommed.tenantIdHeader }
      },
      data,
      withCredentials: true
    })
  }

  public async fetch<T>(
    link: Link,
    data?: unknown,
    uriParameters?: Record<string, string>
  ): Promise<Result<T, NetworkError | HttpRequestError<T>>> {
    let [result, error] = await this.internalFetch<T>(link, data, uriParameters)

    if (error instanceof HttpRequestError) {
      const httpError = error as HttpRequestError<{ _links?: { refresh: Link } }>

      if (httpError.statusCode === 403 && httpError.data?._links?.refresh) {
        await this.internalFetch(httpError.data._links.refresh)

        const [retryResult, retryError] = await this.internalFetch<T>(
          link,
          data,
          uriParameters
        )

        result = retryResult
        error = retryError
      }

      if (error) {
        return Err(error)
      }
    } else if (error) {
      Err(error)
    }

    return Ok(result?.data)
  }
}

decorate(injectable(), BoommedService)
