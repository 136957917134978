import React, { createContext, useCallback, useContext, useState } from 'react'

interface TemplatedFormContextProps {
  data: unknown
  onFormChanged: (data?: unknown) => void
}

export const TemplatedFormContext = createContext<TemplatedFormContextProps>(
  {} as TemplatedFormContextProps,
)

export const TemplatedFormContextProvider = ({ children }: React.PropsWithChildren) => {
  const [data, setData] = useState<unknown | undefined>()

  const onFormChanged = useCallback((data?: unknown) => {
    setData(data)
  }, [])

  return (
    <TemplatedFormContext.Provider value={{ onFormChanged, data }}>
      {children}
    </TemplatedFormContext.Provider>
  )
}

export const useTemplatedFormContext = () => useContext(TemplatedFormContext)
