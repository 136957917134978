import { Alert, Snackbar } from '@mui/material'
import React, { createContext, useContext, useState } from 'react'

interface Notification {
  text: string
  severity: 'error' | 'warning' | 'info' | 'success'
}

interface NotificationStore {
  openNotification: (notification: Notification) => void
}

const NotificationContext = createContext<NotificationStore>({
  openNotification: () => undefined
})

export const NotificationContextProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [notification, setNotification] = useState<Notification>()
  const [open, setOpen] = useState(false)

  const openNotification = (newNotification: Notification) => {
    setNotification(newNotification)
    setOpen(true)
  }

  const handleClose = () => {
    setNotification(undefined)
    setOpen(false)
  }

  return (
    <NotificationContext.Provider value={{ openNotification }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={notification?.text}
      >
        <Alert onClose={handleClose} severity={notification?.severity}>
          {notification?.text}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  )
}

export const useNotificationContext = () => useContext(NotificationContext)
