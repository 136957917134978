import { type UserApi } from '@boommed-suite/contracts'
import { Strings } from '@boommed-suite/typescript-crossplatform'
import { mdiScale } from '@mdi/js'
import MDIIcon from '@mdi/react'
import { InputAdornment, Stack, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import i18next from 'i18next'
import React from 'react'
import { AutocompleteElement, TextFieldElement } from 'react-hook-form-mui'
import { DateTimePickerElement } from 'react-hook-form-mui/date-pickers'
import { useTranslation } from 'react-i18next'
import { ClientSearchField } from '../ClientSearchField/ClientSearchField'
import { DiagnosticAreaSearchField } from '../DiagnosticAreaSearchField/DiagnosticAreaSearchField'
import { MedicalActSearchField } from '../MedicalActSearchField/MedicalActSearchField'
import { PatientSearchField } from '../PatientSearchField/PatientSearchField'
import { SymptomsSearchField } from '../SymptomsSearchField/SymptomsSearchField'

interface TemplatedFieldProps {
  field: string
  item: UserApi.TemplateField
}

export const buildOptionText = (
  value: UserApi.TemplateFieldValue<Record<string, unknown>>,
) =>
  `${i18next.t(value.name)} ${value.description ? '|' : ''} ${i18next.t(
    value?.description ?? Strings.empty(),
  )}`

interface AutocompleteTemplateFieldProps {
  item: UserApi.TemplateField
  field: string
  multiple?: boolean
}

const AutocompleteTemplateField = ({
  multiple = false,
  field,
  item,
}: AutocompleteTemplateFieldProps) => {
  const { t } = useTranslation()

  return (
    <AutocompleteElement
      label={t(item.label)}
      multiple={multiple}
      name={field}
      options={(item.values ?? []).map((value) => ({
        id: value.name,
        label: buildOptionText(value),
      }))}
      required={item.required}
      autocompleteProps={{
        isOptionEqualToValue: (option, value) => option.label === value?.label,
      }}
    />
  )
}

export const TemplatedField = ({ field, item }: TemplatedFieldProps) => {
  const { t } = useTranslation()

  switch (item.type) {
    case 'text':
    case 'email':
      return (
        <TextFieldElement
          name={field}
          label={t(item.label)}
          required={item.required}
        />
      )
    case 'text_area':
      return (
        <TextFieldElement
          name={field}
          label={t(item.label)}
          required={item.required}
          multiline={true}
          minRows={3}
        />
      )
    case 'datetime':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePickerElement
            name={field}
            label={t(item.label)}
            required={item.required}
            ampm={false}
          />
        </LocalizationProvider>
      )
    case 'text[]':
      return <AutocompleteTemplateField item={item} field={field} multiple />
    case 'text[single]':
      return <AutocompleteTemplateField item={item} field={field} />
    case 'client':
      return <ClientSearchField required={item.required} field={field} />
    case 'patient':
      return <PatientSearchField required={item.required} field={field} />
    case 'symptoms':
      return <SymptomsSearchField required={item.required} field={field} />
    case 'diagnostic_area':
      return (
        <DiagnosticAreaSearchField required={item.required} field={field} />
      )
    case 'medical_act':
      return <MedicalActSearchField required={item.required} field={field} />
    case 'weight':
      return (
        <TextFieldElement
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">
                <Stack spacing={1} direction="row">
                  <Typography variant="body2" component="h2">
                    {t('weight_kg')}
                  </Typography>
                  <MDIIcon path={mdiScale} size={1} />
                </Stack>
              </InputAdornment>
            ),
          }}
          name={field}
          label={t(item.label)}
          required={item.required}
        />
      )
    default:
      return null
  }
}
