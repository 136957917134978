import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import PeopleIcon from '@mui/icons-material/People'
import {
  Avatar,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AppRoutes, useAppNavigate } from '../../../app/AppRouter'
import { CustomPalette } from '../../../app/Theme'
import { useAppContext } from '../../../app/contexts/AppContext'
import { styles } from './MasterDrawer.styles'

interface MasterDrawerProps {
  children?: React.ReactNode
  open: boolean
}

const UsersMenu = () => {
  const { t } = useTranslation()
  const { menu, tenant } = useAppContext()
  const navigate = useAppNavigate()

  const { _links } = menu?.items?.users ?? {}

  const onUsersClick = useCallback(() => {
    if (_links?.list && tenant?.id) {
      navigate(AppRoutes.users, { tenantId: tenant.id })
    }
  }, [tenant])

  return (
    _links?.list && (
      <>
        <ListItemButton onClick={onUsersClick}>
          <ListItemIcon>
            <PeopleIcon sx={styles.icon} />
          </ListItemIcon>
          <ListItemText primary={t('users')} />
        </ListItemButton>
      </>
    )
  )
}

const ClientsMenu = () => {
  const { t } = useTranslation()
  const { menu, tenant } = useAppContext()
  const navigate = useAppNavigate()

  const { _links } = menu?.items?.clients ?? {}

  const onClientsClick = useCallback(() => {
    if (_links?.list && tenant?.id) {
      navigate(AppRoutes.clients, { tenantId: tenant.id })
    }
  }, [tenant])

  return (
    _links?.list && (
      <>
        <ListItemButton onClick={onClientsClick}>
          <ListItemIcon>
            <PeopleIcon sx={styles.icon} />
          </ListItemIcon>
          <ListItemText primary={t('clients')} />
        </ListItemButton>
      </>
    )
  )
}

const AppointmentsMenu = () => {
  const { t } = useTranslation()
  const { menu, tenant } = useAppContext()
  const navigate = useAppNavigate()

  const { _links } = menu?.items?.appointments ?? {}

  const onAppointmentsClick = useCallback(() => {
    if (_links?.list && tenant?.id) {
      navigate(AppRoutes.appointments, { tenantId: tenant.id })
    }
  }, [tenant])

  return (
    _links?.list && (
      <>
        <ListItemButton onClick={onAppointmentsClick}>
          <ListItemIcon>
            <LocalHospitalIcon sx={styles.icon} />
          </ListItemIcon>
          <ListItemText primary={t('appointments')} />
        </ListItemButton>
      </>
    )
  )
}

const DrawerItems = () => {
  const navigate = useAppNavigate()
  const { tenant } = useAppContext()

  const onAvatarClick = useCallback(() => {
    if (tenant?.id) {
      navigate(AppRoutes.tenant, { tenantId: tenant.id })
    }
  }, [tenant])

  return (
    <Stack color={CustomPalette.master.white}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={styles.avatar.container}
      >
        <IconButton onClick={onAvatarClick}>
          <Avatar alt={tenant?.name} sx={styles.avatar.image}>
            {tenant?.name?.[0]}
          </Avatar>
        </IconButton>
        <Typography align="center" margin={2} variant="h4">
          {tenant?.name}
        </Typography>
      </Grid>

      <UsersMenu />
      <ClientsMenu />
      <AppointmentsMenu />
    </Stack>
  )
}

export const MasterDrawer = ({ children, open }: MasterDrawerProps) => (
  <main style={open ? { paddingLeft: '250px' } : undefined}>
    {open ? (
      <Drawer open={false} variant="permanent" sx={styles.drawer}>
        <DrawerItems />
      </Drawer>
    ) : undefined}
    {children}
  </main>
)
