import { type SuiteApi } from '@boommed-suite/contracts'
import { mdiCat, mdiDog, mdiPawOff } from '@mdi/js'
import MDIIcon from '@mdi/react'
import AddIcon from '@mui/icons-material/Add'
import { Avatar, Chip, Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useInjection } from 'inversify-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import { AppRoutes, buildNavigationPath } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { useService } from '../../hooks/useService'
import { MasterPage, type MasterPageAction } from '../MasterPage/MasterPage'
import { styles } from './AppointmentsPage.styles'
import { NewAppointment } from './components/NewAppointment'

const SPECIES_ICON_MAP = {
  cat: <MDIIcon path={mdiCat} size={1} />,
  dog: <MDIIcon path={mdiDog} size={1} />,
}

const SpeciesIcon = ({ species }: { species: string }) => {
  return SPECIES_ICON_MAP[species] ?? <MDIIcon path={mdiPawOff} size={1} />
}

const truncateFullName = (fullName: string) => {
  const [firstName, secondName] = fullName.split(' ')

  const truncatedSecondName = secondName?.length > 0 ? `${secondName[0]}.` : ''

  return `${firstName} ${truncatedSecondName}`.trim()
}

export const AppointmentsPage = () => {
  const boommedService = useInjection(BoommedService)
  const { t } = useTranslation()
  const { formatDate } = useDateFormatter()
  const { menu, tenant } = useAppContext()

  const { fetching, data: appointments } =
    useService<SuiteApi.AppointmentsListResponse>(
      {
        service: async () => {
          const listLink = menu?.items?.appointments?._links?.list

          if (listLink !== undefined) {
            const [pagedAppointments] =
              await boommedService.fetch<SuiteApi.AppointmentsListResponse>(
                listLink,
              )

            return pagedAppointments
          }

          return undefined
        },
      },
      [],
    )

  const masterActions = useMemo(() => {
    const actions: MasterPageAction[] = []

    const displayNewButton = appointments?._links.new

    if (displayNewButton && tenant?.id) {
      actions.push({
        title: t('add'),
        icon: <AddIcon />,
        href: buildNavigationPath(AppRoutes.appointmentAdd, {
          tenantId: tenant.id,
          action: 'add',
        }),
      })
    }
    return actions
  }, [appointments, tenant])

  return (
    <MasterPage
      title={t('appointments')}
      menuOpen={true}
      actions={masterActions}
    >
      <DataGrid
        loading={fetching}
        sx={styles.root}
        columnBufferPx={5}
        rows={appointments?.page?.elements ?? []}
        columns={[
          {
            field: 'clientName',
            headerName: t('appointments_client_name'),
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            renderCell: (params) => <strong>{params.value}</strong>,
          },
          {
            field: 'petName',
            headerName: t('appointments_pet_name'),
            flex: 1,
          },
          {
            field: 'species',
            headerName: t('appointments_species'),
            flex: 1,
            renderCell: (params) => (
              <Stack direction="row" alignItems="center" spacing={1}>
                <SpeciesIcon species={params.value} />
                <span style={{ textTransform: 'capitalize' }}>
                  {params.value}
                </span>
              </Stack>
            ),
          },
          {
            field: 'diagnosticArea',
            headerName: t('appointments_diagnostic_area'),
            flex: 1,
            renderCell: (params) => (
              <Chip
                style={{ textTransform: 'capitalize' }}
                label={params.value}
              />
            ),
          },
          {
            field: 'veterinaryName',
            headerName: t('appointments_veterinary_name'),
            flex: 1,
            renderCell: (params) => (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar sx={styles.veterinaryAvatar}>{params.value[0]}</Avatar>
                <span>{truncateFullName(params.value)}</span>
              </Stack>
            ),
          },
          {
            field: 'date',
            headerName: t('appointments_date'),
            flex: 1,
            valueGetter: (params) => formatDate(params),
          },
          {
            field: 'comment',
            headerName: t('appointments_comment'),
            flex: 1,
          },
        ]}
        checkboxSelection
        autoHeight
        hideFooterPagination
      />
      <NewAppointment />
    </MasterPage>
  )
}
