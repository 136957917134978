import React from 'react'
import { createRoot } from 'react-dom/client'
import 'reflect-metadata'
import { App } from './app/App'

import './app/i18n/i18n'

const rootElement = document.querySelector('#root')

if (!rootElement) {
  throw new Error('Failed to find root element')
}

const root = createRoot(rootElement)

root.render(<App />)
