import { Strings } from '@boommed-suite/typescript-crossplatform'

export const configuration = {
  boommed: {
    baseUrl: process.env.BOOMMED_BASE_URL,
    tenantIdHeader: { 'X-Api-Parent-Tenant': process.env.BOOMMED_TENANT_ID_HEADER }
  },
  google: {
    clientId: process.env.GOOGLE_CLIENT_ID ?? Strings.empty()
  }
}
