import { Stack, Typography } from '@mui/material'
import React, { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../app/Theme'

export const UnexpectedErrorPage = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation()

  return (
    <Stack
      justifyContent="center"
      alignContent="center"
      textAlign="center"
      direction="column"
      sx={{ paddingTop: theme.spacing(5) }}
    >
      <Typography variant="h2">Oops!</Typography>
      <Typography variant="h4">{t('unexpected_error')}</Typography>
      <Typography variant="h5" fontStyle="italic">
        {children}
      </Typography>
    </Stack>
  )
}
