import { type UserApi } from '@boommed-suite/contracts'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Grid,
  Stack,
  Typography,
  type SxProps,
  type Theme,
} from '@mui/material'
import i18next from 'i18next'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useAppNavigate } from '../../app/AppRouter'
import { TemplatedField, buildOptionText } from './TemplatedField'
import { styles } from './TemplatedForm.styles'

interface TemplatedFormProps<T> {
  title?: string
  fields?: Record<string, UserApi.TemplateField>
  onSubmit: (values: T) => void
  onChange?: () => void
  onCancel?: () => void
  cancelText?: string
  submitText?: string
  initialValues?: Record<string, unknown>
  sx?: SxProps<Theme>
  displaySaveButton?: boolean
  displayCancelButton?: boolean
}

const buildValidationSchema = (fields: Record<string, UserApi.TemplateField>) =>
  yup.object(
    Object.entries(fields).reduce((acc, [key, item]) => {
      if (item.required) {
        switch (item.type) {
          case 'text':
            acc[key] = yup
              .string()
              .required(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'email':
            acc[key] = yup
              .string()
              .email(i18next.t('invalid_email'))
              .required(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'datetime':
            acc[key] = yup
              .date()
              .required(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'text[]':
            acc[key] = yup
              .array()
              .min(
                1,
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'text[single]':
            acc[key] = yup
              .object()
              .required(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'client':
            acc[key] = yup
              .object()
              .required(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'patient':
            acc[key] = yup
              .object()
              .required(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'symptoms':
            acc[key] = yup
              .array()
              .min(
                1,
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'diagnostic_area':
            acc[key] = yup
              .array()
              .min(
                1,
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'medical_act':
            acc[key] = yup
              .array()
              .min(
                1,
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          case 'weight':
            acc[key] = yup
              .number()
              .positive(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
              .required(
                i18next.t('field_required', { field: i18next.t(item.label) }),
              )
            break
          default:
            break
        }
      }
      return acc
    }, {}),
  )

const buildOptionsInitialValues = (
  fields: Record<string, UserApi.TemplateField>,
  values: Record<string, unknown>,
  key: string,
) =>
  fields?.[key]?.values?.reduce<Array<{ id: string, label: string }>>(
    (acc, value) => {
      if (((values[key] ?? []) as string[]).includes(value.name)) {
        acc.push({
          id: value.name,
          label: buildOptionText(value),
        })
      }

      return acc
    },
    [],
  )

const buildInitialValues = (
  fields: Record<string, UserApi.TemplateField>,
  values: Record<string, unknown>,
) =>
  Object.entries(fields).reduce((acc, [key]) => {
    switch (fields[key].type) {
      case 'text':
      case 'email':
        acc[key] = values[key] || ''
        break
      case 'text[]':
        acc[key] = buildOptionsInitialValues(fields, values, key)
        break
      case 'text[single]':
        acc[key] = values[key] || ''
        break
      default:
        break
    }

    return acc
  }, {})

interface FormChangedProps {
  onChange: () => void
  fields: string[]
}

function FormChanged({ onChange, fields }: FormChangedProps) {
  const watchedFields = useWatch({
    name: fields,
  })

  useEffect(() => {
    onChange()
  }, watchedFields)

  return null
}

type ButtonRef = React.RefObject<HTMLButtonElement> | null

export function TemplatedForm<T = Record<string, unknown>>({
  title,
  fields = {},
  onCancel,
  onSubmit,
  onChange,
  cancelText,
  submitText,
  initialValues = {},
  sx = {},
  displayCancelButton = true,
  displaySaveButton = true,
}: TemplatedFormProps<T>) {
  const { t } = useTranslation()
  const navigate = useAppNavigate()

  const validationSchema = useMemo(() => buildValidationSchema(fields), [])

  const defaultValues = useMemo(
    () => buildInitialValues(fields, initialValues),
    [],
  )

  const formFields = Object.entries(fields)
    .sort(([_, a], [__, b]) => (a.order < b.order ? -1 : 1))
    .map(([key, item], index) => (
      <React.Fragment key={index}>
        <TemplatedField field={key} item={item} />
      </React.Fragment>
    ))

  const cancel = useCallback(() => {
    if (onCancel) {
      onCancel()
    } else {
      navigate(-1)
    }
  }, [])

  const submitButtonRef = React.useRef(null)

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      direction="column"
      sx={{ ...styles.container, ...sx }}
    >
      {title && (
        <Typography variant="h3" noWrap>
          {title}
        </Typography>
      )}

      <FormContainer
        defaultValues={defaultValues}
        onSuccess={(values) => {
          onSubmit(values as T)
        }}
        resolver={yupResolver(validationSchema)}
        FormProps={{ style: styles.form }}
      >
        <Stack direction="column" spacing={4}>
          {formFields}
          <Grid container direction="row" columnGap={10}>
            <Grid item xs>
              {displayCancelButton ? (
                <Button
                  color="secondary"
                  fullWidth
                  variant="contained"
                  onClick={cancel}
                >
                  {cancelText ?? t('cancel')}
                </Button>
              ) : null}
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                ref={submitButtonRef}
                style={{ display: displaySaveButton ? 'block' : 'none' }}
              >
                {submitText ?? t('submit')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
        <FormChanged
          fields={Object.keys(fields)}
          onChange={() => {
            if (!displaySaveButton) {
              onChange?.()
              ;(submitButtonRef as ButtonRef)?.current?.click()
            }
          }}
        />
      </FormContainer>
    </Grid>
  )
}
