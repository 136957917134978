import { CustomPalette, type GradientPaletteOptions, theme } from '../../../app/Theme'

export const styles = {
  drawer: {
    '& .MuiPaper-root': {
      paddingTop: theme.spacing(4),
      width: '250px',
      top: theme.mixins.toolbar.height,
      border: 'none',
      background: (theme.palette.primary as GradientPaletteOptions).gradient
    }
  },
  subItemIcon: {
    paddingLeft: theme.spacing(2)
  },
  avatar: {
    container: { marginBottom: theme.spacing(4) },
    image: {
      width: theme.spacing(10),
      height: theme.spacing(10)
    }
  },
  icon: {
    color: CustomPalette.master.white
  }
}
