export const styles = {
  root: {
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  bigLogo: { width: '366px', height: '260px' },
  smallLogo: { width: '145px', height: '27px', marginTop: '32px' }
}
