export const styles = {
  help: {
    width: '311px',
    height: '48px',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '32px',
    '& a': {
      color: '#009688',
      fontWeight: 'bold'
    }
  }
}
