import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en/translation.json'
import pt from './locales/pt-PT/translation.json'

const resources = { pt: { translation: pt }, en: { translation: en } }

void i18n.use(initReactI18next).init({
  lng: 'pt',
  fallbackLng: 'en',
  debug: false,
  resources,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (format === 'monthExtent') {
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }

        return new Intl.DateTimeFormat(lng, options).format(value as never)
      }

      return value
    }
  }
})

export default i18n
