import type React from 'react'
import { CustomPalette, theme } from '../../app/Theme'

export const styles: Record<string, React.CSSProperties> = {
  root: { marginLeft: theme.spacing(4), marginRight: theme.spacing(4) },
  searchBar: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex'
  },
  searchBardInput: { flexGrow: 1 },
  searchBarIcon: { padding: theme.spacing(1) },
  clientNameCellContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  footer: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deleteButton: { backgroundColor: CustomPalette.master.red }
}
