import { ThemeProvider, createTheme, type PaletteColorOptions } from '@mui/material'
import { ptBR } from '@mui/x-data-grid/locales'
import React, { type ReactNode } from 'react'

export type GradientPaletteOptions = PaletteColorOptions & { gradient: string }

const primary: GradientPaletteOptions = {
  main: '#22A1A8',
  gradient: 'linear-gradient(135deg, #22A1A8 0%, #60BF8F 100%)',
}

export const CustomPalette = {
  master: {
    primary,
    main: '#191F24',
    white: '#FFFFFF',
    red: '#FF0049',
    grey: '#E0E0E0',
    lightGrey: '#F2F3F7',
  },
}

export const theme = createTheme(
  {
    palette: {
      primary,
      secondary: {
        main: '#F2F2F2',
      },
    },
    typography: {
      h1: {
        fontSize: '38px',
        lineHeight: '60px',
        letterSpacing: '-0.1px',
      },
      h3: {
        fontSize: '30px',
        lineHeight: '34px',
        letterSpacing: 'normal',
      },
      h4: {
        fontSize: '20px',
        lineHeight: '32px',
        letterSpacing: '0.1px',
      },
      h5: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
      },
      h6: {
        fontSize: '12px',
        lineHeight: '15px',
        letterSpacing: '0.3px',
      },
      subtitle1: {
        fontSize: '19px',
        lineHeight: '28px',
        letterSpacing: '0.11px',
      },
    },
    mixins: {
      toolbar: {
        height: 120,
      },
    },
  },
  ptBR,
)

export const Theme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)
