import { Guid } from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import {
  AsyncAutoComplete,
  type AsyncAutoCompleteProps,
} from '../AsyncAutoComplete/AsyncAutoComplete'

interface MockMedicalActResponse {
  id: Guid
  name: string
}

type MedicalActSearchFieldProps = Pick<
AsyncAutoCompleteProps<MockMedicalActResponse>,
'onItemSelected' | 'required'
> & { field?: string }

export const MedicalActSearchField = ({
  onItemSelected,
  required,
  field = 'medical_act',
}: MedicalActSearchFieldProps) => {
  const { t } = useTranslation()
  const boommedService = useInjection(BoommedService)

  const fetchData = useCallback(async (): Promise<MockMedicalActResponse[]> => {
    return [
      {
        id: Guid.New(),
        name: 'fisiotherapy',
      },
      {
        id: Guid.New(),
        name: 'exame',
      },
      {
        id: Guid.New(),
        name: 'appointment',
      },
    ]
  }, [boommedService])

  return (
    <AsyncAutoComplete
      fetchData={fetchData}
      resolveLabel={(item) => item.name}
      isEqualValue={(item, value) => item.id === value?.id}
      onItemSelected={onItemSelected}
      label={t('medical_act')}
      required={required}
      multiple={true}
      name={field}
      renderOption={(optionProps, option) => {
        return (
          <li key={option.id.toString()} {...optionProps}>
            {option.name}
          </li>
        )
      }}
    />
  )
}
