import { type LoggerService, type LogLevel } from '@boommed-suite/typescript-crossplatform'
import { decorate, injectable } from 'inversify'

export class ConsoleLogger implements LoggerService {
  private levels = new Set<string>()

  private write<T extends object, E extends Error>(
    level: LogLevel,
    message: string,
    context?: string,
    metadata?: T | string,
    error?: E | string
  ) {
    if (!this.levels.has(level)) {
      return
    }
    const log = this.buildLog(message, context, metadata)

    if (error) {
      // eslint-disable-next-line no-console
      console[level](log, error)
    } else {
      // eslint-disable-next-line no-console
      console[level](log)
    }
  }

  private buildLog<T extends object>(
    message: string,
    context?: string,
    metadata?: T | string
  ): string {
    const { writeMetadata, writeContext } = this.sanitizeLog(context, metadata)
    let consoleMessage = ''

    // NOTE: avoid adding undefined fields to message
    if (writeContext) {
      consoleMessage = `${consoleMessage} [Context: ${writeContext}]`
    }

    consoleMessage = `${consoleMessage} ${message}`

    // NOTE: avoid adding undefined fields to message
    if (writeMetadata) {
      consoleMessage = `${consoleMessage} [Metadata: ${JSON.stringify(writeMetadata)}]`
    }

    return consoleMessage.trim()
  }

  private sanitizeLog<T extends object>(context?: string, metadata?: T | string) {
    let writeMetadata = metadata
    let writeContext = context

    if (typeof writeMetadata === 'string') {
      writeContext = writeMetadata
      writeMetadata = undefined
    }

    return {
      writeMetadata,
      writeContext
    }
  }

  public log<T extends object>(message: string, metadata?: T | string, context?: string) {
    this.write('log', message, context, metadata)
  }

  public error<T extends object, E extends Error>(
    message: string,
    metadata?: T | string,
    error?: E | string,
    context?: string
  ) {
    this.write('error', message, context, metadata, error)
  }

  public warn<T extends object, E extends Error>(
    message: string,
    metadata?: T | string,
    error?: E | string,
    context?: string
  ) {
    this.write('warn', message, context, metadata, error)
  }

  public debug<T extends object>(
    message: string,
    metadata?: T | string,
    context?: string
  ) {
    this.write('debug', message, context, metadata)
  }

  public setLogLevels (levels: LogLevel[]) {
    this.levels = new Set(levels)
  }
}

decorate(injectable(), ConsoleLogger)
