import { type GradientPaletteOptions, theme } from '../../../app/Theme'

export const styles = {
  menu: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      background: (theme.palette.primary as GradientPaletteOptions).gradient,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    },
    background: (theme.palette.primary as GradientPaletteOptions).gradient,
    color: theme.palette.common.white
  },
  icon: {
    color: theme.palette.common.white
  }
}
