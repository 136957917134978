import { CustomPalette, theme } from '../../app/Theme'

export const styles = {
  root: { marginLeft: theme.spacing(4), marginRight: theme.spacing(4) },
  userNameCellContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  footer: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  deleteButton: { backgroundColor: CustomPalette.master.red },
  rolesButton: { textTransform: 'none' }
}
