import { type UserApi } from '@boommed-suite/contracts'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNext from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography
} from '@mui/material'
import React, { useCallback } from 'react'
import { AppRoutes, useAppNavigate } from '../../../app/AppRouter'
import { styles } from './TenantCard.styles'

export const TenantCard = ({
  tenant: { name, id, _links }
}: {
  tenant: UserApi.TenantDetailResponse
}) => {
  const navigate = useAppNavigate()

  const onNavigateClick = useCallback(() => {
    navigate(AppRoutes.tenant, { tenantId: id })
  }, [])

  return (
    <Card sx={styles.root} role="tenant-card" raised>
      <CardHeader
        avatar={
          <Avatar alt={name} sx={styles.avatar}>
            {name[0]}
          </Avatar>
        }
        sx={styles.header}
      />
      <CardContent>
        <Typography variant="subtitle1" noWrap>
          {name}
        </Typography>
        <CardActions sx={styles.actions}>
          {_links.update && (
            <IconButton>
              <EditIcon />
            </IconButton>
          )}
          <IconButton aria-label={id} onClick={onNavigateClick}>
            <NavigateNext />
          </IconButton>
        </CardActions>
      </CardContent>
    </Card>
  )
}
