import { CustomPalette, type GradientPaletteOptions, theme } from '../../app/Theme'

export const styles = {
  appBar: {
    background: CustomPalette.master.main
  },
  toolbar: {
    paddingTop: theme.spacing(1)
  },
  smallLogo: {
    width: '18px',
    paddingBottom: theme.spacing(1.25)
  },
  actions: {
    bottom: theme.spacing(4),
    position: 'relative'
  },
  actionsButton: {
    background: (theme.palette.primary as GradientPaletteOptions).gradient
  },
  actionsContainer: {
    paddingBottom: theme.spacing(7)
  }
}
