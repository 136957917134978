import { Strings } from '@boommed-suite/typescript-crossplatform'
import {
  AppBar,
  Fab,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  type SxProps,
  type Theme
} from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes, useAppNavigate } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { styles } from './MasterPage.styles'
import { MasterDrawer } from './components/MasterDrawer'
import { UserProfile } from './components/UserProfile'

export interface MasterPageAction {
  icon: React.ReactNode
  href?: string
  title?: string
  sx?: SxProps<Theme>
  onClick?: () => void
}

interface MasterPageProps {
  title?: string
  children?: React.ReactNode
  actions?: MasterPageAction[]
  menuOpen?: boolean
}

const MasterPageActionButton = ({ href, icon, title, onClick, sx }: MasterPageAction) => (
  <Link to={href ?? Strings.empty()}>
    <Fab
      title={title}
      color="primary"
      aria-label={title}
      sx={sx ?? styles.actionsButton}
      onClick={() => onClick?.()}
    >
      {icon}
    </Fab>
  </Link>
)

export const MasterPage = ({
  title,
  children,
  actions = [],
  menuOpen = false
}: MasterPageProps) => {
  const navigate = useAppNavigate()
  const { t } = useTranslation()
  const { menu } = useAppContext()

  const onMyTenantsClick = useCallback(() => {
    navigate(AppRoutes.tenants)
  }, [])

  return (
    <>
      <AppBar position="relative" sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <Grid container>
            <Grid item xs={1} />
            <Grid container item xs>
              <Grid item xs>
                <Grid>
                  <IconButton onClick={onMyTenantsClick} color="inherit">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <img
                        style={styles.smallLogo}
                        alt="boommed-small-logo"
                        src={menu?._links?.smallLogoColor?.href}
                      />
                      {title !== t('tenants')
                        ? (
                        <Typography variant="h5">{t('tenants')}</Typography>
                          )
                        : null}
                    </Stack>
                  </IconButton>
                </Grid>
                <Grid>
                  <Typography variant="h1" fontWeight={400}>
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <UserProfile />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container sx={styles.actionsContainer}>
        <Grid item xs justifyContent="flex-end" container sx={styles.actions}>
          <Stack direction="row" spacing={2}>
            {actions.map((action, index) => (
              <MasterPageActionButton key={index} {...action} />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <MasterDrawer open={menuOpen}>{children}</MasterDrawer>
    </>
  )
}
